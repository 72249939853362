import React, { ReactElement } from 'react';
import './Rules.scss';

const Et: React.FC = (): ReactElement => {
  return (
    <div className="Et rules-wrapper">
      <h2>KAMPAANIAREEGLID</h2>
      <ol>
        <li>
          Kampaania <span className="bold">“Hea maitse võidab alati!”</span> on
          alates <span className="bold">01.09.2024</span> kuni{' '}
          <span className="bold">30.09.2024</span> toimuv tarbijamäng (edaspidi
          kampaania), mida korraldab Fazer Eesti OÜ, (asukoht: Pärnu maantee
          158/1, Tallinn; edaspidi korraldaja).
        </li>

        <li>
          Kampaania läbiviimise kord on kehtestatud korraldaja poolt
          käesolevates reeglites. Kõik korraldaja otsused kampaania läbiviimisel
          on lõplikud ning kõigile kampaanias osalejatele kohustuslikud.
        </li>
        <li>
          Kampaaniatooted:
          <ul>
            <li>6411401015090 Karl Fazer piimašokolaad 200g</li>
            <li>
              6411401015151 Karl Fazer piimašokolaad tervete metspähklitega 200g
            </li>
            <li>6411401015144 Karl Fazer piimašokolaad pähkli-rosina 200g</li>
            <li>6411401015861 Karl Fazer tume šokolaad 200g</li>
            <li>
              6416453015265 Karl Fazer tume šokolaad tervete metspähklitega 200g
            </li>
            <li>
              6416453014367 Karl Fazer tume šokolaad karamelliseeritud
              mandlitükkidega 200g
            </li>
            <li>
              6416453014374 Karl Fazer piimašokolaad küpsisetükkidega 180g
            </li>
            <li>
              6416453014381 Karl Fazer piimašokolaad Dumle toffeetükkidega 180g
            </li>
            <li>
              6416453014442 Karl Fazer piimašokolaad soolase karamelli tükkidega
              180g
            </li>
            <li>6416453014466 Karl Fazer Pretzel piimašokolaad 180g</li>
            <li>6416453014411 Karl Fazer Ässä Mix piimašokolaad 200g</li>
            <li>
              6416453014732 Karl Fazer piimašokolaad mündimaitselise
              mandlikrokandiga 180g
            </li>
            <li>6416453015647 Karl Fazer valge šokolaad 131g</li>
            <li>6416453017405 Karl Fazer Crunchy šokolaadibatoon 55g</li>
            <li>6416453072633 Karl Fazer Dark 70% kommikarp 150g</li>
            <li>6416453072640 Karl Fazer piimašokolaadikompvekid 150g</li>
            <li>6416453072657 Karl Fazer Selection kommikarp 150g</li>
            <li>6416453071117 Karl Fazer piimašokolaadikompvekid 270g</li>
            <li>
              6416453071148 Karl Fazer piimašokolaadikompvekid mustikatäidisega
              270g
            </li>
            <li>
              6416453071193 Karl Fazer piimašokolaadikompvekid
              vaarikajogurtitäidisega 270g
            </li>
            <li>6411404021036 Karl Fazer piimašokolaadikompvekid 3kg</li>
          </ul>
        </li>
        <li>
          <li className="bold">
            Kampaanias osalemiseks tuleb osta vähemalt üks Karl Fazer toode ja
            registreerida ostutšeki number kampaanialehel www.fazer.ee.
            Kampaanias osalemine on tasuta. Kampaania auhinnafondi kuulub 3x
            iPhone 15 Pro MAX, mis loositakse välja 01.10.2024. Auhinnafondi
            koguväärtus on 4197€. Auhinna kättesaamiseks tuleb kampaania
            korraldajale esitada ostutšekk.
          </li>
          <ul>
            <li>
              Loosimises osalemiseks võib ühe ostutšeki numbri esitada ainult
              ühe korra. Sama ostutšeki mitmekordsel registreerimisel osaleb
              ainult esimesena saabunud registreering.
            </li>
            <li className="bold">Isikuandmete töötlemise kord</li>
            <li>
              Fazer Eesti OÜ töötleb kampaania käigus osaleja poolt esitatud
              isikuandmeid vastavalt Eesti Vabariigis kehtivatele seadustele
              kampaania läbiviimise eesmärgil.
            </li>
            <li>
              Kampaanias osaleja annab Fazer Eesti OÜ-le nõusoleku töödelda
              esitatud andmeid järgnevatel eesmärkidel:
              <ul>
                <li>kampaania tulemuste kohta kokkuvõtete tegemiseks;</li>
                <li>
                  kampaaniaga seotud koostööpartneritele info edastamiseks;
                </li>
                <li>võitjate välja loosimiseks ja nende teavitamiseks;</li>
                <li>võitjate nimede avaldamiseks kampaania veebilehel.</li>
              </ul>
            </li>
            <li>
              Kampaanias osalejatel on õigus saada lisainfot Fazer Eesti OÜ
              poolt isikuandmete töötlemise kohta. Selleks tuleb saata e-kiri
              aadressile: <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
            </li>
            <li>
              Isikuandmete vastutav töötleja on Fazer Eesti OÜ, registrikood
              10057691, Pärnu maantee 158/1, Tallinn 11317; tel 6502421;{' '}
              <a href="mailto:info@fazer.ee">info@fazer.ee</a>
            </li>
            <li>
              Kogutud andmeid säilitatakse kolm kuud alates kampaania
              lõpukuupäevast ehk kuni 31.12.2024. Võitjate täielik nimekiri on
              veebilehel üleval kuni 31.10.2024.{' '}
            </li>
          </ul>
        </li>
        <li>
          <p className="bold">
            01.10.2024 kell 12.00 loositakse kõikide kampaanias osalejate vahel
            välja 3x iPhone 15 Pro MAX.
          </p>
          <p>
            Auhinna loosimises osalevad kõik veebis registreerunud isikud
            kampaania reeglites esitatud perioodil (alates keskkonna
            avanemisest). Võitjat informeeritakse telefoni või meili teel.
            Loosimise juures viibib vähemalt üks Fazer Eesti OÜ esindaja.
          </p>
        </li>
        <li>
          Auhinna võitja nimi avaldatakse internetis aadressil{' '}
          <a href="http://www.fazer.ee">www.fazer.ee </a> hiljemalt loosimisele
          järgneval kolmandal tööpäeval. Võitjaga võetakse korraldaja esindaja
          poolt ühendust andmete ja auhinna kättetoimetamisviisi täpsustamiseks.
        </li>
        <li>
          Juhul, kui auhinna võitja ei vasta korraldaja poolt kehtestatud
          kampaania tingimustele või kui ta ei ole alles hoidnud toote
          ostutšekki, millel oleva numbri ta edastas kampaania korraldajale
          veebi vahendusel; on õigus auhind uuesti välja loosida järgmisele
          isikule. Samuti juhul, kui auhinna võitja ei ole hiljemalt nädala
          jooksul alates, tema kui auhinna võitja nime avalikustamist internetis
          aadressil www.fazer.ee olnud veebis esitatud kontaktandmetel
          kättesaadav andmete täpsustamiseks, on korraldajal õigus jätta talle
          auhind üle andmata ning korraldada uus loosimine.{' '}
        </li>
        <li>
          Korraldajalt ükskõik missuguse kampaania auhinna saaja annab loa
          korraldajale kasutada võitja nime meedias, reklaamides,
          PR-kampaaniates jms üritustel ilma võitjale selle eest täiendavat tasu
          maksmata ja vastavat kokkulepet sõlmimata.{' '}
        </li>
        <li>
          Kampaania käigus kampaanias osaleja poolt võidetud auhinda ei asendata
          võitja nõudmisel teist liiki auhinnaga ega hüvitata võitjale auhinna
          maksumust rahas, v.a juhul, kui auhind asendatakse teise samaväärse
          auhinnaga korraldaja otsusel.{' '}
        </li>
        <li>
          Auhinna võitjale üleandmisega seotud riiklikud maksud tasub korraldaja
          Eesti Vabariigi seadustes sätestatud korras ja tähtaegadel.{' '}
        </li>
        <li>
          Kampaanias osaleja poolt kampaania tingimuste eiramise või
          korraldajale enda kohta valede või ebatäpsete andmete esitamise
          korral, samuti muude ebaõnnestumiste korral (korraldajal ei õnnestu
          auhinna võitjaga kontakti saada), mis ei ole tingitud korraldaja
          süüst, ei vastuta korraldaja kampaanias osaleja auhindade loosimisest
          või auhinna kätteandmisest kõrvalejäämise eest.{' '}
        </li>
        <li>
          Vääramatu jõu (force majeure) asjaolude ilmnemisel on korraldajal
          ühepoolne õigus katkestada kampaania ja jätta auhinnad välja loosimata
          ja/või auhinnad võitjatele üle andmata, teatades sellest koheselt
          kampaanias osalejatele meedia vahendusel.{' '}
        </li>
        <li>
          Kõik kampaaniast tulenevad vaidlused lahendatakse vastavalt Eesti
          Vabariigi seadusandlusele.
        </li>
        <li>
          Kõik pretensioonid seoses kampaania korraldamise ja/või läbiviimisega
          tuleb saata kirjalikult kampaania korraldaja asukohta: Pärnu maantee
          158/1, 11317 Tallinn märksõnaga “KAMPAANIA" hiljemalt 01.10.2024.
          Kaebused, mis esitatakse peale nimetatud kuupäeva, ei võeta menetluse.
          Laekunud pretensioonidele vastatakse 3 tööpäeva jooksul. Kampaania
          lisainfo telefonil 650 2421;{' '}
          <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
        </li>
      </ol>
    </div>
  );
};

export default Et;
