import React, { ReactElement } from 'react';
import './Main.scss';

// import Prizes from '../../assets/images/banana_prizes.png';
import Banner from '../../assets/images/prizes_iphone.png';
import { HashLink } from 'react-router-hash-link';
import Rules from '../../components/Rules/Rules';
import Winners from '../../components/winners/Winners';
import Register from '../../components/register/Register';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/images/logo.png';
import Wheat from '../../assets/images/wheat.png';

const Main: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="Main">
      {/* <img src={Wheat} alt="" className="wheat" /> */}
      <section className="banner container">
        <div className="columns">
          <div className="banner-header column is-full-desktop is-half-mobile">
            <img src={Logo} alt="fazer logo" className="fazer-logo" />
            <h1>
              <span>HEA MAITSE</span> <span>VÕIDAB ALATI</span>
            </h1>
            {/* <h2>ŠOKOLAADIGA!</h2> */}
            <p>
              <span>OSTA KARL FAZERI ŠOKOLAADI,</span>{' '}
              <span>REGISTREERI OSTUTŠEKK KAMPAANIALEHEL</span>{' '}
              <span>JA VÕID VÕITA IPHONE 15 PRO MAXI</span>
            </p>
            <div className="campaign-period">
              <span>{t('campaign period')}</span>
            </div>
            <HashLink to="#register" className="button">
              {t('register')}
            </HashLink>
          </div>
          <div className="column is-half-mobile">
            <img
              src={Banner}
              alt="fazer campaign prize"
              className="banner-prize"
            />
          </div>
        </div>
      </section>
      {/* <section className="header">
        <Header />
      </section> */}

      <section className="register-form container">
        <div className="columns">
          <div className="column">
            <Register />
          </div>
        </div>
      </section>
      <section className="rules-section container" id="rules">
        <Rules />
      </section>
      <section className="winners-section container" id="winners">
        <Winners />
      </section>
    </div>
  );
};

export default Main;
