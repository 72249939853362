import React, { ReactElement, useEffect, useState } from 'react';
import './Winners.scss';
import _ from 'lodash';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

interface PrizeList {
  iphone: Pick<any, string | number | symbol>[];
}

const Winners: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const [winners, setWinners] = useState<PrizeList>({
    iphone: [],
  });

  const getWinners = async () => {
    const res = await axios.get('/api/dataservice/winners/');

    // console.log(res);
    const prizes = _.mapValues(_.groupBy(res.data, 'prize'), (plist) =>
      plist.map((prize) => _.omit(prize, 'prize'))
    );

    setWinners({
      iphone: prizes.iphone === undefined ? [] : prizes.iphone,
    });
  };

  useEffect(() => {
    getWinners();
  }, []);

  return (
    <div className="winners">
      <h2>{t('winners')}</h2>
      <div className="winners-wrapper columns">
        <div className="column">
          <ul className={winners.iphone.length === 0 ? 'hidden' : ''}>
            {winners.iphone.map(function (item: any, index: any) {
              return (
                <li key={index}>{item.first_name + ' ' + item.last_name}</li>
              );
            })}
            {/* <li>name name</li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Winners;
